@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import "./themes/variables";

* {
  margin : 0;
  padding: 0;
}

html,
body,
* {
  font-family: $font-family;
}

html,
body {
  background      : #f6f6f6;
  background-color: #f6f6f6 !important;
  padding-right   : 0px !important
}

html,
body {
  overflow-x: hidden;
}

.loaderdiv {
  position       : fixed;
  top            : 0px;
  bottom         : 0px;
  left           : 0px;
  right          : 0px;
  z-index        : 111;
  background     : $white-color;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

/*Login Css Start*/
.loginlogo_div {
  background         : url('components/assets/img/bg.jpg');
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : cover;
  display            : flex;
  align-items        : center;
  justify-content    : center;
  height             : 100%;
  padding            : 30px 40px;
  position           : relative;
  width              : 100%;
  z-index            : 1;

  // background-attachment: fixed;
  &:after {
    content   : '';
    position  : absolute;
    top       : 0px;
    left      : 0px;
    right     : 0px;
    bottom    : 0px;
    background: $black-color;
    opacity   : 0.5;
  }

  a {
    position: relative;
    z-index : 1;

    img {
      height: 90px;
    }
  }
}

.loginform {
  align-items    : center;
  display        : flex;
  justify-content: center;
  min-height     : 100vh;
  overflow       : hidden;
  position       : relative;
  z-index        : 1;

  .loginmain_div {
    padding: 30px;
    width  : 100%;

    .headlogin_div {
      h2 {
        font-size  : 32px;
        color      : $dark-color;
      }

      p {
        font-size: 14px;
        color    : $font-color;
      }
    }
  }
}

.maxwidth500 {
  max-width: 500px;
}

.btn-primary {
  background  : $primary-color !important;
  border-color: $primary-color !important;
  height      : 54px;
  font-size   : 16px !important;
  font-weight : 700 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.75;
  }
}

.btn-primary-outline {
  background  : transparent !important;
  border-color: $primary-color !important;
  color       : $primary-color !important;
  height      : 54px;
  font-size   : 16px !important;
  font-weight : 700 !important;

  &:hover {
    opacity: 0.75;
  }
}
.btn-primary-light {
  background  : rgba($primary-color, 0.10) !important;
  border-color: rgba($primary-color, 0.10) !important;
  color       : $primary-color !important;
  height      : 54px;
  font-size   : 16px !important;
  font-weight : 700 !important;
  &:hover {
    opacity: 0.75;
  }
}

Form {
  .formfooter {
    font-size: 16px;
    color    : $dark-color;

    a {
      color          : $primary-color;
      text-decoration: underline;
      font-weight    : 600;
    }
  }

  .forgotpassword {
    a {
      font-size      : 16px;
      text-decoration: underline;
      color          : $dark-color;
      font-weight    : 600;
    }
  }

  .form-group {
    .uploadphoto {
      border         : 1px dashed $border-color;
      border-radius  : 8px;
      padding        : 30px 10px;
      width          : 100%;
      display        : flex;
      align-items    : center;
      justify-content: center;
      flex-direction : column;
      cursor         : pointer;

      svg {
        font-size    : 30px;
        color        : $primary-color;
        margin-bottom: 5px;
      }

      p {
        color        : $font-color;
        margin-bottom: 0px;
      }
    }

    label {
      font-size    : 14px;
      color        : $dark-color;
      font-weight  : 500;
      margin-bottom: 3px;
    }

    textarea.form-control {
      height        : auto;
      padding-top   : 15px;
      padding-bottom: 15px;
    }

    .form-control {
      border       : 1px solid $border-color;
      height       : 52px;
      border-radius: 8px;
      font-size    : 14px;
      padding      : 0px 16px;
      box-shadow   : none;

      &::placeholder {
        color: $font-color;
      }
    }
    .form-select {
      border       : 1px solid $border-color;
      height       : 52px;
      border-radius: 8px;
      font-size    : 14px;
      padding      : 0px 16px;
      box-shadow   : none;

      &::placeholder {
        color: $font-color;
      }
    }
  }
  .fileupload {
    width: 100%;
    border: 2px dashed #ddd;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    margin-bottom: 18px;
    cursor: pointer;	
    .uplaodicon {
      color: #B09FF5;
      font-size: 40px;
    }
    h3 {
      font-size: 16px;
      color: #000;
      margin-top: 8px;	
      margin-bottom: 0px;
    }
  }  
  .displaynone {
    display: none;
  }
  .formimg {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
    .closebtn {
      width: 20px;
      cursor: pointer;
      height: 20px;
      background: #e60d0d;
      text-align: center;
      line-height: 20px;
      color: #fff;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      top: -5px;
      padding: 3px;
    }
    img {
      width: 80px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .timesetting_div {
    .form-check {
      display: flex;
      align-items: center;
      .form-label {
        display: flex;
    align-items: center;
    gap: 15px;
    flex-grow: 1;
        .day_badge {
          background: $primary-color;
          padding: 10px 15px;
          border-radius: 10px;
          text-align: center;
          display: inline-block;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          min-width: 70px;
      }        
      }
    }
  }
}

/*Login Css Ends*/
/*Membership Packages Css Start*/
.pricingpackage {
  border-radius: 16px;
  padding      : 20px;
  background   : $white-color;
  box-shadow   : 0px 0px 10px rgba($black-color, 0.1);
  overflow     : hidden;

  .pricinghead {
    text-align: center;
    position  : relative;

    .setdefault {
      background : rgba($primary-color, 0.10);
      color      : $primary-color;
      position   : absolute;
      right      : -20px;
      padding    : 5px 10px 4px;
      font-size  : 10px;
      top        : -21px;
      font-weight: 500;
    }

    h5 {
      font-size  : 16px;
      color      : $dark-color;
      font-weight: 600;
    }

    h2 {
      font-size  : 30px;
      font-weight: 700;
      color      : $primary-color
    }

    h6 {
      font-size      : 14px;
      color          : $dark-color;
      display        : flex;
      align-items    : center;
      justify-content: center;
      flex-direction : column;

      span {
        display    : block;
        margin-top : 4px;
        font-size  : 10px;
        font-style : italic;
        font-weight: 600;
      }
    }
  }

  ul {
    list-style: none;
    margin    : 20px 0px 0px 0px;
    padding   : 20px 0px 20px 0px;
    border-top: 1px solid rgba($black-color, 0.1);

    li {
      font-size      : 13px;
      color          : $dark-color;
      display        : flex;
      margin-bottom  : 10px;
      justify-content: space-between;
      margin-bottom  : 10px;

      svg {
        color      : $primary-color;
        font-size  : 18px;
        margin-left: 5px;
      }

    }
  }

  &.activepackage {
    background: $primary-color;

    .pricinghead {
      text-align: center;

      h5 {
        color: $white-color;
      }

      h2 {
        color: $white-color
      }

      h6 {
        color: $white-color;
      }
    }

    ul {
      border-top: 1px solid rgba($white-color, 0.5);

      li {
        color: $white-color
      }
    }

    .btn-primary {
      background: $white-color !important;
      color     : $dark-color !important
    }
  }
}

.minheight33 {
  min-height: 33px;
}

.headinner {
  h4 {
    font-size  : 20px;
    color      : $dark-color;
  }

  p {
    color    : $font-color;
    font-size: 14px;
  }
}

.h300 {
  height: 300px !important;
}

.paymentdone {
  h2 {
    font-size  : 28px;
    margin-top : 25px;
  }
}

.modalhead {
  font-size  : 20px;
  margin     : 0px;
}

.modalsubhead {
  font-size: 14px;
  margin   : 0px;
}

.btn-secondary {
  border    : 1px solid rgba($black-color, 0.1) !important;
  background: transparent !important;
  color     : rgba($black-color, 0.5) !important;
}

/*Membership Packages Css Ends*/
@media only screen and (max-width: 767.98px) {

  .header,
  .maincontent_div {
    margin-left: 0 !important;
  }

  button.btnclose,
  button.btnopen {
    border : 0 !important;
    display: block !important;
    outline: 0 !important;
    z-index: 101;
  }

  button.btnopen {
    background: transparent !important;
    box-shadow: none !important;
    left      : 15px;
    position  : absolute;
    top       : 30px;

    svg {
      font-size: 34px;
    }
  }
}

.sidebar {
  background     : #fff;
  height         : 100%;
  left           : 0;
  overflow-x     : hidden;
  overflow-y     : auto;
  padding        : 20px;
  position       : fixed;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  box-shadow     : 0px 0px 10px rgba($black-color, 0.07);
  top            : 0;
  width          : 270px;
  z-index        : 1031;

  .sidebarlogo_div {
    text-align    : center;
    border-bottom : 1px solid #e3e3e3;
    padding-bottom: 20px;
    margin-bottom : 20px;

    img {
      height: 60px;
    }
  }

  @media only screen and (max-width: 767.98px) {
    &.sidebar-open {
      left      : 0;
      transition: all 1s ease-in-out;
    }

    &.sidebar-closed {
      left      : -320px;
      transition: all 1s ease-in-out;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  button.btnclose {
    background: rgba($black-color, 0.50);
    bottom    : 0;
    left      : 0;
    padding   : 0;
    position  : fixed;
    top       : 0;
    transition: all 1s ease-in-out;

    &.sidebarbg-open {
      transition: all 1s ease-in-out;
      width     : 100%;
    }
  }

}

.header {
  margin-left: 270px;
  position   : relative;
  z-index    : 1;
  padding    : 10px 15px;

  .headerright {
    background   : $white-color;
    padding      : 4px 8px;
    border-radius: 50px;

    .linksheader {
      svg {
        font-size: 16px;
        color    : $dark-color
      }
    }

    .dropdown {
      .dropdown-toggle {
        background: transparent !important;
        border    : none !important;

        &::after {
          display: none;
        }
      }
    }

    a {
      display: inline-flex;

      &:hover {
        opacity: 0.75;
        cursor : pointer;
      }
    }
  }

  .headerright {
    img {
      width        : 45px;
      height       : 45px;
      border-radius: 50%;
    }
  }
}

.maincontent_div {
  -webkit-backface-visibility: hidden;
  backface-visibility        : hidden;
  margin-left                : 270px;
  padding                    : 15px;
  position                   : relative;
  transition                 : all .3s ease;
}

.form-check {
  .form-check-input:checked {
    background-color: $primary-color;
    border-color    : $primary-color;
  }

  .form-check-label {
    font-size: 14px;
  }
}

.mainbreadcrumb {
  h1 {
    font-size    : 28px;
    color        : $black-color;
    font-weight  : 700;
    margin-bottom: 0px;
  }

  nav {
    ol {
      margin-bottom: 5px;

      li {
        a {
          color          : $black-color;
          font-size      : 14px;
          font-weight    : 500;
          text-decoration: none;
        }
      }
    }
  }
}

.sidebarouter {
  .navitem {
    padding        : 12px 20px;
    position       : relative;
    color          : $dark-color;
    font-size      : 14px;
    text-decoration: none;
    margin-bottom  : 10px;
    display        : flex;
    font-weight    : 500;

    svg {
      font-size   : 20px;
      margin-right: 10px;
      min-width   : 20px;
    }

    &:before {
      content      : '';
      position     : absolute;
      top          : 0px;
      left         : -20px;
      height       : 100%;
      background   : $primary-color;
      width        : 0;
      border-radius: 0px 14px 14px 0px;
    }

    &.active,
    &:hover {
      &:before {
        width: 4px;
      }

      color        :$white-color;
      background   : $primary-color;
      display      : flex;
      border-radius: 6px;
    }
  }
}

.stats {
  background   : $white-color;
  padding      : 20px;
  border-radius: 10px;
  box-shadow   : 0px 0px 10px rgba($black-color, 0.07);
  display      : flex;
  align-items  : center;

  .statsicon {
    width          : 80px;
    height         : 80px;
    background     : rgba($primary-color, 0.07);
    border-radius  : 50%;
    flex-shrink: 0;
    display        : flex;
    align-items    : center;
    justify-content: center;

    &.statsblue {
      background: rgba(176, 159, 245, 0.12);

      svg {
        color: #b09ff5;
      }
    }

    &.statsgreen {
      background: rgba(40, 199, 111, .12);

      svg {
        color: #28c76f;
      }
    }

    svg {
      font-size: 24px;
      color    : $primary-color;
    }
  }

  .stats-cont {
    width       : calc(100% - 50px);
    padding-left: 15px;

    h4 {
      font-size  : 16px;
      color      : $black-color;
      font-weight: 600;
    }

    p {
      margin-bottom: 0px;
      font-size    : 14px;
    }
  }
}

.maxwidth120 {
  max-width: 120px;
}

.lh-40 {
  line-height: 40px !important;
}

.card {
  border            : none !important;
  --bs-border-radius: 12px !important;
  box-shadow        : 0px 0px 10px rgba($black-color, 0.07);

  .card-header {
    background    : $white-color;
    padding-top   : 12px;
    padding-bottom: 12px;

    .form-select {
      font-size: 14px;
    }

    h5 {
      font-size    : 18px;
      margin-bottom: 0;
      font-weight  : 600;
    }
  }
}

.searchfield {
  border       : 1px solid #dadada;
  border-radius: 8px;
  box-shadow   : none;
  font-size    : 14px;
  padding      : 8px 16px;
}

.rdt_Pagination {
  margin-top: 5px;
}

.post-image {
  width        : 60px;
  min-width        : 60px;
  height       : 60px;
  object-fit   : cover;
  border       : 1px solid #e0e0e0;
  padding      : 4px;
  border-radius: 10px;
}

.video-icon {
  position     : absolute;
  font-size    : 30px;
  color        : $white-color;
  left         : 32px;
  background   : rgba($black-color, 0.35);
  border-radius: 50%;
}
.custom-table {
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;

  .rdt_TableHead {
    .rdt_TableHeadRow {
      background    : rgba($primary-color, 0.07);
      border-radius : 10px;
      margin        : 5px 0;
      padding-top   : 15px;
      padding-bottom: 15px;

      .rdt_TableCol {
        color      : $dark-color;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .table_img {
    width: 60px;
    min-width: 60px;
    height: 60px;
    object-fit: cover;
  }  
  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        padding-top   : 10px;
        padding-bottom: 10px;
        font-size: 14px;      
      }

      border-radius: 10px;
      background   : #fff;
      border       :1px solid #e0e0e0;
      margin       : 5px 0;

      .tag {
        color      : $primary-color;
        font-weight: 500;
        margin-left: 4px;
      }
    }
  }
}

.tabledropdown {
  border       : 1px solid #dadada !important;
  border-radius: 6px !important;
  box-shadow   : none !important;
  font-size    : 13px !important;
  max-width    : 150px;
}

@mixin btnaction {
  width          : 34px;
  min-width      : 34px;
  height         : 34px;
  font-size      : 18px;
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  border-radius  : 50%;
  margin         : 0px 3px;
  color          : $white-color;
}

.btnview {
  background: $primary-color;
  @include btnaction
}

.btnedit {
  @include btnaction;
  background: #28c76f;
}

.btndelete {
  @include btnaction;
  background: #d32424;
}

.postimgvideo {
  label {
    width          : 45px;
    min-width      : 45px;
    height         : 45px;
    background     : rgba($primary-color, 0.10);
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    border-radius  : 8px;

    svg {
      font-size: 24px;
      color    : $primary-color;
    }
  }
}

.modal {
  .modal-body {
    .postlist {
      align-items  : center;
      border       : 1px solid $border-color;
      border-radius: 15px;
      display      : flex;
      margin-bottom: 12px;
      padding      : 15px;

      img {
        height    : 40px;
        object-fit: cover;
        width     : 40px;
      }

      h2 {
        font-size    : 14px;
        font-weight  : 600;
        margin-bottom: 0;
        margin-left  : 13px;
      }
    }

    .btn-primary {
      height   : 48px;
      font-size: 14px !important;
    }
  }
}

.menugrid {
  position: relative;

  img {
    height    : 140px;
    width     : 100%;
    box-shadow: 0px 0px 10px rgba($black-color, 0.1);
    object-fit: cover;
  }

  .deletemenu {
    width          : 22px;
    min-width      : 22px;
    height         : 22px;
    background     : $primary-color;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    border-radius  : 5px;
    color          : $white-color;
    position       : absolute;
    top            : 5px;
    right          : 5px;
  }
}

.uploadmenu {
  width         : 100%;
  border        : 1px dashed $border-color;
  padding       : 20px;
  border-radius : 10px;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  cursor        : pointer;

  svg {
    font-size: 32px;
    color    : $primary-color;
  }
}

.addicon {
  width          : 45px;
  min-width      : 45px;
  height         : 45px;
  background     : rgba($primary-color, 0.10) !important;
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  border-radius  : 8px;
  border         : none;
  color          : $primary-color;
  font-size      : 20px !important;
}

.modal {
  &.itemmodal {
    .modal-content {
      .modal-body {
        Form {
          .form-group {
            .form-control {
              height: 45px;
            }

            textarea.form-control {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.bookingstab {
  .customtabs , .customtabs2 {
    float: left;
  }
}

.customtabs {
  &.nav-tabs {
    border: none;

    li {
      &.nav-item {
        button {
          &.nav-link {
            border       : 1px solid rgba($primary-color, 0.10);
            border-radius: 40px;
            color        : $primary-color;
            font-weight  : 500;
            padding      : 10px 20px;
            font-size    : 15px;
            margin       : 0px 5px 10px 0px;
            background   : rgba($primary-color, 0.10);

            &.active,
            &:hover {
              background: $primary-color;
              color     : $white-color
            }
          }
        }
      }
    }
  }
}
.customtabs2 {
  &.nav-tabs {
    border: none;

    li {
      &.nav-item {
        button {
          &.nav-link {
            border       : 1px solid rgba($primary-color, 0.10);
            border-radius: 40px;
            color        : $primary-color;
            font-weight  : 500;
            padding      : 7px 13px;
            font-size    : 13px;
            margin       : 0px 3px 6px 0px;
            background   : rgba($primary-color, 0.10);

            &.active,
            &:hover {
              background: $primary-color;
              color     : $white-color
            }
          }
        }
      }
    }
  }
}
.mt_30 {
  margin-top: 30px;
}
.view_profile {
  .user_info {
    border       : 1px solid #dee2e6;
    border-radius: 12px;
    padding      : 30px 20px;
    position     : relative;
    overflow     : hidden;

    .profilepic {
      width     : 140px;
      height    : 140px;
      object-fit: cover;
      border: 1px solid #f5f5f5;
    }

    h4 {
      font-size    : 16px;
      font-weight  : 600;
      margin-bottom: 5px;
      margin-top   : 15px;
    }

    p {
      font-size    : 14px;
      margin-bottom: 0px;
    }
  }

  .form-group {
    border-bottom : 1px dashed #dee2e6;
    padding-bottom: 13px;

    span {
      font-size: 14px;
    }

    .form-label {
      font-weight: 600;
      font-size  : 14px;
    }
  }
}

.font-size-14 {
  font-size: 14px;
}

.postsdetail {
  .replyBox {
    display: flex;

    img {
      width     : 40px;
      min-width : 40px;
      height    : 40px;
      border    : 1px solid $primary-color;
      padding   : 2px;
      object-fit: cover;
    }

    .commentarea {
      width: calc(100% - 50px);

      .form-control {
        font-size    : 14px;
        height       : 40px;
        margin-left  : 10px;
        padding-right: 45px;
      }

      button {
        width        : 30px;
        height       : 30px;
        border-radius: 50%;
        padding      : 0;
        position     : absolute;
        top          : 5px;
        right        : 0px;
      }
    }
  }

  .aftContent {
    width: 100%;

    img {
      width     : 40px;
      min-width : 40px;
      height    : 40px;
      border    : 1px solid $primary-color;
      padding   : 2px;
      object-fit: cover;
    }

    .comBox {
      width: calc(100% - 40px);

      // margin-left: 10px;
      .cardCount {
        .cardcountlinks {
          font-size: 11px;

          svg {
            font-size: 15px;
          }
        }
      }

      .repCommnet {
        background-color: #edf2f6;
        border-radius   : 10px;
        max-width       : 100% !important;
        min-width       : 140px;
        padding         : 14px 10px 14px 14px;

        .dropdown {
          button {
            &.dropdown-toggle {
              &.btn-primary {
                width    : 16px;
                height   : 16px;
                display  : inline-flex;
                transform: translateY(-4px);
              }
            }
          }
        }

        h3 {
          font-size    : 14px;
          font-weight  : 600;
          margin-bottom: 7px;
        }

        p {
          font-size    : 12px;
          margin-bottom: 0;
        }
      }
    }
  }

  .cardCount {
    margin-top: 10px;

    .cardcountlinks {
      text-decoration: none;
      display        : inline-flex;
      align-items    : center;
      color          : rgba($black-color, 0.50);
      font-size      : 13px;
      font-weight    : 500;

      svg {
        font-size   : 18px;
        margin-right: 3px;
      }
    }
  }

  .post-description {
    font-size: 14px;

    .tag {
      color      : $primary-color;
      font-weight: 500;
    }
  }

  .dropdown {
    button {
      &.dropdown-toggle {
        &.btn-primary {
          background : transparent !important;
          border     : none !important;
          color      : $black-color !important;
          padding    : 0px;
          width      : 20px;
          height     : 20px;
          line-height: normal;

          svg {
            font-size: 20px;
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .dropdown-menu {
      min-width    : 100px;
      border-radius: 15px 0px 15px 15px;
      box-shadow   : 0px 0px 10px rgba($black-color, 0.07);

      .dropdown-item {
        font-size  : 14px;
        color      : $dark-color;
        font-weight: 500;

        &:active,
        &:hover {
          background: rgba($primary-color, 0.10);
          color     : $primary-color;
        }
      }
    }
  }

  .mediaUser {
    align-items: center;
    display    : flex;

    img {
      border    : 1px solid $primary-color;
      height    : 60px;
      object-fit: cover;
      width     : 60px;
      min-width : 60px;
      padding   : 3px;
    }

    .mediaContent {
      margin-left: 1em;

      h3 {
        font-size  : 15px;
        color      : $dark-color;
        font-weight: 600;
      }

      h4 {
        font-size    : 13px;
        margin-bottom: 0px;
        opacity      : 0.70;
      }
    }
  }

  .mediaimg {
    img {
      height       : 450px;
      object-fit   : cover;
      border-radius: 12px;
    }
  }
}

.bookingtable {
  .table-responsive{
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  }
  >.table-responsive {
    box-shadow   : 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    table {
      &.table {
        &.tableinner {
          border-collapse: separate;
          border-spacing : 0px 10px;

          thead {
            tr {
              th {
                background    : rgba($primary-color, 0.07);
                padding   : 15px;
                &:first-child{
                  border-radius: 10px 0px 0px 10px;
                }
                &:last-child{
                  border-radius: 0px 10px 10px 0px;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding   : 15px;
                border-top:1px solid #dee2e6;
                border-bottom:1px solid #dee2e6;
                vertical-align: middle;
                &:first-child{
                  border-radius: 10px 0px 0px 10px;
                  border-left:1px solid #dee2e6;
                }
                &:last-child{
                  border-radius: 0px 10px 10px 0px;
                  border-right:1px solid #dee2e6;
                }
              }
            }
          }
        }

        tr {
          td {
            font-size: 14px;
            padding  : 15px;
            b {
              font-weight: 600;
            }
            .dtlimg {
              width: 80px;
              height: 80px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
.notificationlist{
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);
  padding: 25px;
  border-radius: 12px;
  border-left: 3px solid $primary-color;
  background: $white-color;
  h2{
    font-size: 16px;
    font-weight: 700;
    margin-bottom:0px;
  }
  p{
    margin-bottom: 0;
    font-size: 14px;
  }
  .notifytime{
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    svg{
      margin-right: 4px;
    }
  }
}
.vieworderdetail {
  p{
    display: flex;
    justify-content: space-between;
  }
}
.Active.badge {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
  display: inline-block;
  font-size: 13px;
  line-height: normal;	
}
.Inactive.badge {
  background-color: rgba(146, 0, 0, 0.12);
  color: #920000;
  display: inline-block;
  font-size: 13px;
  line-height: normal;	
}
.min_width140 {
  min-width: 140px;
}
.min_width110 {
  min-width: 110px;
}
.ck-editor__editable {
  min-height: 200px;
}
table.openinghourtable.table 
{
tbody 
{
  tr
  {
    &:last-child 
    {
      td {
        border: 0px;
      }
    }
  }
}
}
.sidebar {
  .submenu {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .submenu.open {
    display: block;
    opacity: 1;
  }  
}
.screeingtop_table {
  .table {
    thead {
      th {
        padding: 15px;
        font-size: 14px;
        background: $black-color;
        color: $white-color
      }
    }
    tbody {
      td {
        font-size: 14px;
        padding  : 15px;
      }
    }
  }
  .table-responsive {
    margin-bottom: 30px;
  }
}
.screeningcard_table {
  background: $white-color;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 20px;
  .screencard_head {
    background: $primary-color;
    padding: 14px 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    h3 {
      font-size: 16px;
      font-weight:600;
      color: $white-color;
      margin-bottom: 0px;
    }
  }
  .screencard_body {
      table.table {
        margin-bottom: 0px;
        thead {
          th {
            vertical-align: middle;
            font-size: 14px;
            border: 0px;
          }
        }
        tbody {
          td {
            vertical-align: middle;
            font-size: 14px;
            border: 0px;
            .screenstatus_icon {
              width: 36px;
            }
          }
        }
      }
  }
}
.tab-content {
  table.table {
    tbody {
      td {
        font-size: 14px;
        padding: 15px;
        vertical-align: middle;
        .screenstatus_icon {
          width: 36px;
        }
      }
    }
    thead {
      th {
        font-size: 14px;
        padding: 15px;
        vertical-align: middle;
      }
    }
  }
  .screenaccordion {
    .accordion {
      .accordion-item {
        border-radius: 12px;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
        margin-bottom: 10px;
        .accordion-header {
          .accordion-button {
            box-shadow: none;
            border: 0px;
            background: transparent;
            font-size: 16px;
            font-weight: 600;
            color: $black-color;
          }         
        }
      }
    }
  }
}
.modal_Delete.modal {
  .modal-content {
    border: 0px;
    border-radius: 15px;
    .modal-body {
      text-align: center;
      padding: 25px;
      .modaldelete_div {
        margin-bottom: 25px;
        .delete_icon {
          font-size: 60px;
          color: #fd5353;
          margin-bottom: 10px;
        }
        h3 {
          color: $black-color;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 10px;
          line-height: normal;
        }
        p {
          color: #8b8b8b;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.dropdownCustom
{
  margin-top:10px;
  .dropdown-toggle{
    width: 100%;
    text-align: left;
    justify-content: space-between;
    background: transparent !important;
    border-color: #dee2e6 !important;
    color: #000;
    font-weight: 400 !important;
    &:hover,
    &:active,
    &:focus{
      color: #000 !important;
    }
  }
}

.formbold-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.formbold-file-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  flex-direction: column;
}

.formbold-file-input label svg
{
  font-size: 4em;
  opacity: 0.5;
}

.formbold-file-input div{
  font-size: 1.1em;
  font-weight: 600;

}

.formbold-file-input {
  overflow: hidden;
  position: relative;
}

.downloadLink
{
  margin-top: 0.7em;
  display: flex;
  flex-direction: row;
  gap: 5em;
  a{
    color: #0d6efd;
  }
}